<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <form-header
            :title="title"
            :form="form"
            :permissions="permissions"
            :view="view"
            @viewChange="viewChanged"
            @saveForm="saveForm"
            @disableRecord="change_status"
            @newForm="newForm"
            @close="close"
            @cancelForm="cancelForm"
            @edit="view = 'edit'"
          />
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Icon Image" prop="icon_image">
                    <upload-field
                      size="mini"
                      folder="visitor_types_logo"
                      allowedFileTypes="['image/jpeg', 'image/png', 'image/bmp']"
                      :width="100"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.icon_image"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="English Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Require Age" prop="require_age">
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_age"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Require Company" prop="require_company">
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_company"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Require Designation"
                    prop="require_designation"
                  >
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_designation"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Require Email" prop="require_email">
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_email"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Require Gender" prop="require_gender">
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_gender"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Require Address" prop="require_address">
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_address"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item
                    label="Require Nationality"
                    prop="require_nationality"
                  >
                    <el-checkbox
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.require_nationality"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Icon CSS" prop="icon_css">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.icon_css"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Status" prop="status">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.status"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Checkbox,
  Form,
  FormItem,
  Input,
  Option,
  Select,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(Checkbox);
import FormHeader from "@/components/custom/FormHeader.vue";
import UploadField from "@/components/custom/UploadField.vue";
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    title: {
      type: String,
      default: "",
    },
    end_point: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    FormHeader,
    UploadField,
  },
  data() {
    return {
      form: {},
      view: "view",
      rules: {
        name: [
          {
            required: true,
            message: "Please enter Name",
            trigger: "blur",
          },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: null,
        clients_id: null,
        name: null,
        icon_image: null,
        require_company: false,
        require_designation: false,
        require_email: false,
        require_address: false,
        require_age: false,
        require_gender: false,
        require_nationality: false,
        icon_css: null,
        status: "Active",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
      if (this.empty(this.form.id)) this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
  },
  name: "VisitorTypesForm",
};
</script>
