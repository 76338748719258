<template>
  <v-container>
    <v-card>
      <index-header
        :title="title"
        :permissions="permissions"
        :reports="reports"
        @newRecord="newRecord"
        @viewRecord="viewRecord"
        @sizeChanged="sizeChanged"
        @fitlerData="fitlerData"
        @getData="getData"
      />
      <v-divider />
      <v-card-text>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column width="150" prop="icon_image" label="Icon">
            <template slot-scope="scope">
              <image-field
                size="mini"
                :disabled="true"
                :show-zoom="false"
                :image-click="true"
                width="0"
                height="50"
                :value="
                  !empty(scope.row.icon_image) ? scope.row.icon_image : null
                "
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            width="200"
            label="Visitor Type Name"
          ></el-table-column>
          <el-table-column
            prop="require_address"
            label="Req. Address"
            :formatter="colFormatYesNo"
            align="center"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="require_age"
            :formatter="colFormatYesNo"
            align="center"
            label="Req. Age"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="require_company"
            :formatter="colFormatYesNo"
            align="center"
            label="Req. Company"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="require_designation"
            :formatter="colFormatYesNo"
            align="center"
            label="Req. Designation"
            width="150"
          ></el-table-column>
          <el-table-column
            prop="require_email"
            :formatter="colFormatYesNo"
            label="Req. Email"
            width="130"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="require_gender"
            :formatter="colFormatYesNo"
            label="Req. Gender"
            width="130"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="require_nationality"
            :formatter="colFormatYesNo"
            label="Req. Nationality"
            width="150"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="status"
            label="Status"
            width="100"
          ></el-table-column>
          <el-table-column fixed="right" label="..." width="40">
            <template slot-scope="scope">
              <v-icon
                style="cursor: hand"
                color="success"
                class="mr-1"
                @click="viewRecord(scope.row)"
              >
                mdi-eye
              </v-icon>
            </template>
          </el-table-column>
        </el-table>
        <index-footer
          :currentPage="currentPage"
          :total="total"
          :perPage="perPage"
          @sizeChanged="sizeChanged"
          @pageChanged="pageChanged"
        />
      </v-card-text>
      <v-divider />
    </v-card>
    <form-view
      :show="showForm"
      :title="title"
      :perm="permissions"
      :data="selectedRow"
      :end_point="end_point"
      @updated="getData"
      @close="
        selectedRow = null;
        showForm = false;
      "
    />
    <v-overlay :value="loading" :opacity="0.7">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>
<script>
import Vue from "vue";
import {
  Table,
  TableColumn,
  Button,
  Pagination,
  ColorPicker,
} from "element-ui";
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Button);
Vue.use(Pagination);
Vue.use(ColorPicker);
import formView from "./form.vue";
import IndexHeader from "@/components/custom/IndexHeader.vue";
import IndexFooter from "@/components/custom/IndexFooter.vue";
import ImageField from "@/components/custom/ImageField.vue";
export default {
  created() {
    this.getData();
  },
  components: {
    formView,
    IndexHeader,
    IndexFooter,
    ImageField,
  },
  data() {
    return {
      end_point: "clients/visitor-types/",
      tableData: [],
      reports: null,
      search: "",
      title: "Visitor Types List",
      currentPage: 1,
      perPage: 10,
      total: 0,
      sort: "visitor_types.name",
      permissions: [],
      loading: false,
      showForm: false,
      selectedRow: null,
    };
  },
  methods: {
    newRecord() {
      this.showForm = true;
    },
    viewRecord(row) {
      this.selectedRow = row;
      this.showForm = true;
    },
    sizeChanged(page) {
      this.perPage = page;
      this.getData();
    },
    pageChanged(page) {
      this.currentPage = page;
      this.getData();
    },
    fitlerData(search_value, c_page) {
      this.search = search_value;
      this.currentPage = c_page;
      this.getData();
    },
    getData() {
      let f = {
        filter: this.search,
        page: this.currentPage,
        per_page: this.perPage,
        sort: this.sort,
      };
      this.loading = true;
      this.api(this.end_point + "get", f)
        .then((response) => {
          this.loading = false;
          if (response.status == true) {
            this.tableData = response.data.data;
            this.total = response.data.total;
            this.reports = response.data.reports;
            this.permissions = response.data.permissions;
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {},
  name: "VisitorTypesList",
};
</script>
